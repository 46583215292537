@font-face {
    font-family: "Ninetail";
    src: url("../../assets/fonts/Ninetail-regular.otf");
}

@font-face {
    font-family: "Thiccboi";
    src: url("../../assets/fonts/THICCCBOI-Regular.ttf");
}

@font-face {
    font-family: "Thiccboi_Bold";
    src: url("../../assets/fonts/THICCCBOI-Bold.woff2");
}

$primary_font: "Thiccboi";
$secondary_font: "Ninetail";
$bold_font: "Thiccboi_Bold";

* {
    padding: 0;
    margin: 0;
    font-family: 'Thiccboi', sans-serif;
}

@import "./inc/";
@import "./components/";
@import "./screens/";

.rfm-marquee-container {
    overflow-y: hidden !important;
}