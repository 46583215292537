.total_formsec {
    padding: 50px 0;

    .hireme_btn {
        background-color: #C47D3D;
        color: #FFFFFF;
        font-family: "Ninetail";
        border-radius: 25px;
        padding: 5px 35px;
        font-size: 22px;
        border: 4px solid $text_white;
        outline: 2px solid rgba(196, 125, 61, 0.3098039216);
        margin-top: 20px;

        &:hover {
            background-color: $text_white;
            color: $primary_color;
            border: 4px solid $text_white;
            outline: 2px solid rgba(196, 125, 61, 0.3098039216);
            transition: 0.3s ease-in-out;
        }
    }

    .header_sec {
        text-align: center;

        h1 {
            font-family: $secondary_font;
        }

        .head_star_sectwo {
            justify-content: center;


            h4 {
                // font-family: $secondary_font;
                color: $primary_color;
                margin-bottom: 0;
            }
        }
    }

    .mail_form {
        width: 50%;
        margin: auto;
        margin-top: 50px;

        .form-group {
            position: relative;
            position: relative;

            input {
                height: 60px;
                border: 1px solid $primary_color;
                border-radius: 25px;
                font-size: 18px;
                outline: none;
                box-shadow: none;

                &::placeholder {
                    color: #C4C4C4;
                }
            }

            .book_btn {
                position: absolute;
                right: 1%;
                background-color: $primary_color;
                color: $text_white;
                font-family: $secondary_font;
                border-radius: 25px;
                padding: 5px 35px;
                font-size: 16px;
                border: 1px solid $primary_color;
                padding: 10px 35px;
                top: 13%;

                &:hover {
                    background-color: $text_white;
                    color: $primary_color;
                    border: 1px solid $primary_color;
                    transition: 0.3s ease-in-out;
                }
            }
        }

        .achive_sec {
            display: flex;
            justify-content: center;
            align-items: center;

            .first_sec {
                text-align: left;
                background-color: $primary_color;
                padding: 5px 20px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                margin-right: 10px;

                h6 {
                    margin-bottom: 0;
                    color: $text_white;
                    font-size: 12px;
                    font-weight: 600;
                    // margin-right: 10px;
                }
            }

            .second_sec {
                h6 {
                    margin-bottom: 0;
                    font-size: 12px;
                    font-weight: 600;
                    margin-right: 10px;
                }

                &:hover {
                    background-color: $primary_color;
                    padding: 5px 20px;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    transition: 0.5s ease-in-out;

                    h6 {
                        color: $text_white;
                    }
                }
            }

            span {
                display: block;
                margin-right: 10px;

            }
        }

    }
}


@media only screen and (max-width: 991.98px) {
    .total_formsec .mail_form {
        width: 80%;
    }

    .total_formsec .mail_form {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .total_formsec .mail_form .achive_sec .first_sec {
        text-align: left;
        background-color: #C47D3D;
        padding: 5px 2px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-right: 4px;
    }

    .total_formsec .mail_form .achive_sec .first_sec h6 {
        font-size: 8px;
    }

    .total_formsec .mail_form .achive_sec .second_sec h6 {
        font-size: 8px;
    }

    .total_formsec .mail_form .achive_sec span {
        font-size: 8px;
    }

    .total_formsec .mail_form .achive_sec .second_sec:hover {
        background-color: #C47D3D;
        padding: 5px 2px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        transition: 0.5s ease-in-out;
    }
}