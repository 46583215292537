.whatdid_total_sec {
    padding: 50px 0px;

    .content_container {
        padding: 50px 0px;

        .img_sec {
            position: relative;
            text-align: center;

            img {
                width: 100%;
            }

            .shedule_btn {
                // position: absolute;
                // right: 25%;
                // bottom: -3.5%;
                margin-top: 50px;
                background-color: #C47D3D;
                color: #FFFFFF;
                font-family: "Ninetail";
                border-radius: 25px;
                padding: 5px 35px;
                font-size: 16px;
                border: 1px solid #C47D3D;

                &:hover {
                    background-color: $text_white;
                    color: $primary_color;
                    border: 1px solid $primary_color;
                    transition: 0.3s ease-in-out;
                }
            }
        }

        .total_content_sec {
            position: relative;

            .whatbefore_illu {
                position: absolute;
                width: 160px;
                right: 0%;
                top: 23%;
            }

            .exp_head {
                font-weight: 600;
                margin-left: 10px;
                margin-top: 40px;
                margin-bottom: 20px;

                span {
                    color: #C47D3D;
                }
            }

            p {
                text-align: justify;
                margin-left: 10px;
                width: 80%;
            }

            .counted_row {
                position: relative;

                .divide_img {
                    position: absolute;
                    width: 35%;
                    left: 6.5%;
                    top: 0%;
                }
            }

            .counter_sec {
                text-align: center;

                h2 {
                    color: $text_black;
                    font-weight: 800;
                    margin-left: 10px;
                    font-family: $bold_font;
                }

                .align_para {
                    text-align: center;
                    width: auto !important;
                    color: $primary_color;
                    font-weight: 600;
                }
            }
        }
    }
}

.head_star_sec {
    display: flex;
    align-items: center;

    h1 {
        font-family: $secondary_font;
        margin-bottom: 0;
    }

    span {
        font-size: 22px;
        margin-bottom: 0;
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 991.98px) {
    .what_didrow {
        display: flex;
        align-items: center;
    }

    .whatdid_total_sec .content_container .img_sec .shedule_btn {
        right: 13%;
        bottom: -4.5%;
    }

    .whatdid_total_sec .content_container .total_content_sec p {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {

    .whatdid_total_sec .content_container .img_sec .shedule_btn {
        right: 16%;
        bottom: -4.5%;
    }

    .head_star_secthree {
        margin-top: 30px;

        span {
            margin-left: 0;
        }
    }

    .whatdid_total_sec .content_container .total_content_sec .exp_head {
        margin-top: 15px;
        margin-left: 0px;
    }

    .whatdid_total_sec .content_container .total_content_sec p {
        margin-left: 0;
    }

    .counter_row {
        display: flex;

        .counter_col {
            width: 48%;
        }
    }

    .whatdid_total_sec .content_container .total_content_sec .counted_row .divide_img {
        position: absolute;
        width: 84%;
        left: 6%;
        top: -8%;
        display: none;
    }
}