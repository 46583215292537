.total_footer_sec {
    background-color: $primary_background;
    padding: 50px 0px;

    .bottom_content {
        text-align: center;
        color: $primary_color;
        margin-top: 30px;

        span {
            color: $text_black;
            font-weight: 800;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .total_contentsec {
        display: flex;
        justify-content: space-between;

        .left_sec {
            width: 30%;

            .namesec {
                width: max-content;

                img {
                    width: 90%;
                }

                h1 {
                    font-family: "Ninetail";
                    font-size: 58px;
                    font-weight: 600;
                    padding: 0;
                    margin-bottom: 0;
                }

                p {
                    color: $primary_color;
                    text-align: right;
                    padding: 0;
                    font-weight: 600;
                }
            }

            .content {
                text-align: justify;
                color: $dark_background;
                margin-top: 30px;

                span {
                    font-weight: 600;
                }
            }

            .social_sec {
                display: flex;

                span {
                    width: 30px;
                    height: 30px;
                    background-color: $primary_color;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;

                    a {
                        text-decoration: none;
                        color: $text_white;
                        font-size: 14px;
                    }
                }
            }
        }

        .right_sec {
            text-align: right;

            .hireme_btn {
                background-color: #C47D3D;
                color: #FFFFFF;
                font-family: "Ninetail";
                border-radius: 25px;
                padding: 5px 35px;
                font-size: 22px;
                border: 4px solid $text_white;
                outline: 2px solid rgba(196, 125, 61, 0.3098039216);

                &:hover {
                    background-color: $text_white;
                    color: $primary_color;
                    border: 4px solid $text_white;
                    outline: 2px solid rgba(196, 125, 61, 0.3098039216);
                    transition: 0.3s ease-in-out;
                }
            }

            .address_sec {
                text-align: right;
                margin-top: 30px;

                a {
                    display: flex;
                    justify-content: end;
                    color: $dark_background;
                    text-decoration: none;
                    margin-bottom: 15px;
                    font-size: 14px;

                    span {
                        display: block;
                    }

                    .icon {
                        margin-left: 20px;
                        color: $primary_color;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 991.98px) {
    .total_footer_sec .total_contentsec .left_sec {
        width: 45%;
    }

    .total_footer_sec .total_contentsec .right_sec {
        width: 45%;
    }
}

@media only screen and (max-width: 600px) {
    .total_footer_sec .total_contentsec {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .total_footer_sec .total_contentsec .left_sec {
        width: 100%;
    }

    .total_footer_sec .total_contentsec .right_sec {
        width: 100%;
        text-align: left;
        margin-top: 20px;
    }

    .total_footer_sec .total_contentsec .right_sec .address_sec {
        text-align: left;
    }

    .total_footer_sec .total_contentsec .right_sec .address_sec a {
        display: flex;
        justify-content: start;
        color: #2C2C2C;
        text-decoration: none;
        margin-bottom: 15px;
        font-size: 14px;
        flex-direction: row-reverse;
    }

    .total_footer_sec .total_contentsec .right_sec .address_sec a .icon {
        margin-left: 0;
        margin-right: 20px;
    }

    .total_footer_sec .total_contentsec .left_sec .namesec img {
        width: 80%;
    }
}