.total_banner_sec {
    padding-top: 180px;
    background-color: $primary_background;
    height: 85vh;



    .head {
        font-family: "Ninetail";
        color: $primary_color;
        font-size: 48px;

        span {
            font-family: "Ninetail";
            color: $dark_background;
        }
    }

    .orange_quote {
        width: 25px;
        display: block;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .quote {
        font-size: 52px;
        color: $primary_color;
        font-weight: 700;
    }

    .parra_sec {
        width: 90%;

        h6 {
            text-align: justify;
            font-size: 18px;
            line-height: 1.5;
        }

        p {
            text-align: right;
            font-weight: 500;
            color: $primary_color;
        }
    }

    .btnsec {
        margin-top: 15px;

        .hire_btn {
            font-family: "Ninetail";
            color: $text_white;
            background-color: $primary_color;
            border-radius: 25px;
            font-size: 26px;
            padding: 8px 50px;
            // box-shadow: 1px 1px 1px 0px $primary_color;
            border: 4px solid $text_white;
            outline: 2px solid #c47d3d4f;

            &:hover {
                background-color: transparent;
                border: 4px solid transparent;
                color: $primary_color;
                transition: 0.5s ease-in-out;
            }
        }
    }

    .img_sec {
        img {
            width: 535px;
            display: block;
            margin: auto;
            margin-top: -85px;
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .total_banner_sec {
        height: 60vh;
        padding-top: 160px;
    }

    .total_banner_sec .head {
        font-size: 36px;
    }

    .total_banner_sec .btnsec .hire_btn {
        font-size: 22px;
    }



    .total_banner_sec .img_sec img {
        width: 100%;
        margin-top: 0;
    }

}

@media only screen and (max-width: 767.98px) {}

@media only screen and (max-width: 600px) {
    .total_banner_sec {
        padding-top: 60px;
        height: 90vh;
    }

    .banner_row {
        display: flex;
        flex-direction: column-reverse;
    }

    .total_banner_sec .img_sec img {
        // width: 100%;
        margin-bottom: 2rem;
    }
    

    .total_banner_sec .head {
        font-size: 24px;
        margin-bottom: 2rem;
    }

    .total_banner_sec .parra_sec {
        margin-bottom: 2rem;
    }

    .total_banner_sec .parra_sec h6 {
        font-size: 14px;
    }

    .total_banner_sec .orange_quote {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .total_banner_sec .parra_sec p {
        font-size: 14px;
    }

    .total_banner_sec .btnsec .hire_btn {
        font-size: 16px;
        padding: 2px 25px;
    }

}