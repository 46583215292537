@font-face {
  font-family: "Ninetail";
  src: url("../../assets/fonts/Ninetail-regular.otf");
}
@font-face {
  font-family: "Thiccboi";
  src: url("../../assets/fonts/THICCCBOI-Regular.ttf");
}
@font-face {
  font-family: "Thiccboi_Bold";
  src: url("../../assets/fonts/THICCCBOI-Bold.woff2");
}
* {
  padding: 0;
  margin: 0;
  font-family: "Thiccboi", sans-serif;
}

.webnavbar {
  background-color: #FFF8F3;
  padding: 30px 0px 20px 0px;
  position: relative;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 2000;
}
.webnavbar h1 {
  font-family: "Ninetail";
  color: #C47D3D;
}
.webnavbar .elipse {
  position: absolute;
  left: 0;
}
.webnavbar .navbar-toggler {
  border: 1px solid #C47D3D !important;
  box-shadow: none !important;
  outline: none;
  width: 50px;
  color: #C47D3D;
  font-size: 22px;
}
.webnavbar ul {
  margin-left: auto;
}
.webnavbar ul .nav-item {
  margin-right: 30px;
}
.webnavbar ul .nav-item:last-child {
  margin-right: 0;
}
.webnavbar ul .nav-item a {
  color: #C47D3D;
}
.webnavbar ul .nav-item a .star {
  width: 20px;
  display: none;
}
.webnavbar ul .nav-item a:hover {
  transition: 0.5s ease-in-out;
  font-weight: 600;
}
.webnavbar ul .nav-item a:hover .star {
  transition: 0.5s ease-in-out;
  display: initial;
}
.webnavbar ul .nav-item a:hover .box_shadows {
  text-shadow: -1px 1px #C47D3D;
}
.webnavbar ul .nav-item .nav-link .active {
  color: #C47D3D;
}
.webnavbar ul .nav-item .nav-link .active .star {
  transition: 0.5s ease-in-out;
  display: initial;
}
.webnavbar ul .nav-item .nav-link .active .box_shadows {
  text-shadow: -1px 1px #C47D3D;
}

@media only screen and (max-width: 991.98px) {
  .webnavbar .elipse {
    position: absolute;
    left: 0;
    width: 33%;
  }
}
@media only screen and (max-width: 600px) {
  .webnavbar {
    padding: 0;
  }
  .webnavbar .elipse {
    position: absolute;
    left: 0;
    width: 60%;
    display: none;
  }
  .webnavbar {
    box-shadow: 0px 0px 2px #C47D3D;
  }
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #C47D3D;
}
.navbar-nav .nav-link.active .star,
.navbar-nav .nav-link.show .star {
  transition: 0.5s ease-in-out;
  display: initial;
}
.navbar-nav .nav-link.active .box_shadows,
.navbar-nav .nav-link.show .box_shadows {
  text-shadow: -1px 1px #C47D3D;
}

.total_banner_sec {
  padding-top: 180px;
  background-color: #FFF8F3;
  height: 85vh;
}
.total_banner_sec .head {
  font-family: "Ninetail";
  color: #C47D3D;
  font-size: 48px;
}
.total_banner_sec .head span {
  font-family: "Ninetail";
  color: #2C2C2C;
}
.total_banner_sec .orange_quote {
  width: 25px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
}
.total_banner_sec .quote {
  font-size: 52px;
  color: #C47D3D;
  font-weight: 700;
}
.total_banner_sec .parra_sec {
  width: 90%;
}
.total_banner_sec .parra_sec h6 {
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
}
.total_banner_sec .parra_sec p {
  text-align: right;
  font-weight: 500;
  color: #C47D3D;
}
.total_banner_sec .btnsec {
  margin-top: 15px;
}
.total_banner_sec .btnsec .hire_btn {
  font-family: "Ninetail";
  color: #FFFFFF;
  background-color: #C47D3D;
  border-radius: 25px;
  font-size: 26px;
  padding: 8px 50px;
  border: 4px solid #FFFFFF;
  outline: 2px solid rgba(196, 125, 61, 0.3098039216);
}
.total_banner_sec .btnsec .hire_btn:hover {
  background-color: transparent;
  border: 4px solid transparent;
  color: #C47D3D;
  transition: 0.5s ease-in-out;
}
.total_banner_sec .img_sec img {
  width: 535px;
  display: block;
  margin: auto;
  margin-top: -85px;
}

@media only screen and (max-width: 991.98px) {
  .total_banner_sec {
    height: 60vh;
    padding-top: 160px;
  }
  .total_banner_sec .head {
    font-size: 36px;
  }
  .total_banner_sec .btnsec .hire_btn {
    font-size: 22px;
  }
  .total_banner_sec .img_sec img {
    width: 100%;
    margin-top: 0;
  }
}
@media only screen and (max-width: 600px) {
  .total_banner_sec {
    padding-top: 60px;
    height: 90vh;
  }
  .banner_row {
    display: flex;
    flex-direction: column-reverse;
  }
  .total_banner_sec .img_sec img {
    /* width: 65%; */
    margin-bottom: 2rem;
  }
  .total_banner_sec .head {
    font-size: 24px;
    margin-bottom: 2rem;
  }
  .total_banner_sec .parra_sec {
    margin-bottom: 2rem;
}
  .total_banner_sec .parra_sec h6 {
    font-size: 14px;
  }
  .total_banner_sec .orange_quote {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .total_banner_sec .parra_sec p {
    font-size: 14px;
  }
  .total_banner_sec .btnsec .hire_btn {
    font-size: 16px;
    padding: 2px 25px;
  }
}
.total_marquesec {
  padding: 50px 0px;
}
.total_marquesec .writer_sec {
  margin-top: 50px;
  position: relative;
}
.total_marquesec .writer_sec .illu {
  position: absolute;
  width: 10%;
  right: 0%;
}
.total_marquesec .writer_sec .writer_img img {
  width: 100%;
}
.total_marquesec .writer_sec .content_sec {
  padding: 60px 0px;
  margin-left: 60px;
}
.total_marquesec .writer_sec .content_sec .sec_one {
  display: flex;
}
.total_marquesec .writer_sec .content_sec .sec_one:nth-child(2) {
  margin-top: 5px;
}
.total_marquesec .writer_sec .content_sec .sec_one .img_sec .illustrator {
  width: 45%;
}
.total_marquesec .writer_sec .content_sec .sec_one .img_sec .star_img {
  width: 90%;
}
.total_marquesec .writer_sec .content_sec .sec_one .content {
  width: 80%;
}
.total_marquesec .writer_sec .content_sec .sec_one .content h1 {
  font-family: "Ninetail";
}
.total_marquesec .writer_sec .content_sec .sec_one .content h1 span {
  font-family: "Ninetail";
}
.total_marquesec .writer_sec .content_sec .sec_one .content h1 .name {
  color: #C47D3D;
}
.total_marquesec .writer_sec .content_sec .sec_one .content p {
  text-align: justify;
}
.total_marquesec .writer_sec .content_sec .sec_one .content p .parra_bold {
  font-weight: 600;
}
.total_marquesec .writer_sec .content_sec .sec_one .content h6 {
  font-weight: 600;
  margin-left: 28px;
}
.total_marquesec .writer_sec .content_sec .primary_btns {
  display: block;
  margin-top: 50px;
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 8px 35px;
  font-size: 18px;
  border: 1px solid #C47D3D;
  margin-left: 55px;
  box-shadow: 1px 1px 3px 0px rgba(196, 125, 61, 0.7803921569);
}
.total_marquesec .writer_sec .content_sec .primary_btns:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 1px solid #C47D3D;
  transition: 0.3s ease-in-out;
}

.marquee_sec .first_head {
  display: flex;
  align-items: center;
}
.marquee_sec .first_head h1 {
  margin-bottom: 0;
  font-family: "Ninetail";
  color: #C47D3D;
}
.marquee_sec .first_head span {
  display: block;
  margin-right: 10px;
  margin-left: 10px;
  font-family: "Ninetail";
  color: #000000;
}
.marquee_sec .first_head span svg {
  font-size: 26px;
}

@media only screen and (max-width: 991.98px) {
  .marque_row {
    display: flex;
    align-items: center;
  }
  .total_marquesec .writer_sec .content_sec {
    margin-left: 0;
  }
  .total_marquesec .writer_sec .content_sec {
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .total_marquesec .writer_sec .content_sec .sec_one {
    margin-top: 20px;
  }
  .total_marquesec .writer_sec .content_sec .sec_one .content h1 {
    font-size: 28px;
  }
}
.workwithus_total_sec {
  background-color: #FFF8F3;
  padding: 100px 0px;
  position: relative;
}
.workwithus_total_sec .illuimg1 {
  position: absolute;
  width: 84px;
  right: 0;
  top: -14%;
}
.workwithus_total_sec .illuimg2 {
  position: absolute;
  width: 50px;
  bottom: 3%;
}
.workwithus_total_sec .heading {
  font-family: "Ninetail";
  color: #C47D3D;
  text-align: center;
  margin-bottom: 0;
  margin-bottom: 70px;
}
.workwithus_total_sec .heading span {
  color: #000000;
  margin-right: 10px;
  margin-left: 10px;
}
.workwithus_total_sec .toalcard {
  display: flex;
  align-items: center;
}
.workwithus_total_sec .toalcard .work_card {
  text-align: center;
}
.workwithus_total_sec .toalcard .work_card img {
  width: 80px;
}
.workwithus_total_sec .toalcard .work_card h6 {
  color: #000000;
  font-weight: 600;
  margin-bottom: 0;
  width: 55%;
  margin: auto;
  margin-top: 20px;
}
.workwithus_total_sec .toalcard .work_card p {
  font-size: 14px;
  width: 70%;
  margin: auto;
  margin-top: 20px;
  text-align: justify;
}
.workwithus_total_sec .toalcard .work_card .line img {
  width: initial;
}
.workwithus_total_sec .toalcard .work_card .line img:last-child {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  .workwithus_total_sec .illuimg1 {
    top: -9%;
  }
  .workwithus_total_sec {
    padding: 50px 0px;
  }
  .workrow {
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .workwithus_total_sec .illuimg1 {
    top: -7%;
  }
  .workwithus_total_sec .toalcard .line {
    display: none;
  }
  .workwithus_total_sec .toalcard .work_card {
    margin-bottom: 20px;
  }
  .workwithus_total_sec .illuimg2 {
    bottom: -3%;
  }
}
.unleashing_total_sec {
  padding: 100px 0px;
}
.unleashing_total_sec .img_card {
  position: relative;
}
.unleashing_total_sec .img_card img {
  width: 90%;
}
.unleashing_total_sec .img_card .readmore_btn {
  position: absolute;
  left: 60%;
  bottom: 3%;
  background-color: #FFFFFF;
  color: #000000;
  font-weight: 600;
  font-size: 13px;
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  padding: 5px 20px;
}
.unleashing_total_sec .img_card .readmore_btn:hover {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  transition: 0.5s ease-in-out;
}
.unleashing_total_sec .owl-carousel .owl-nav button.owl-prev {
  background-color: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #2C2C2C;
  position: absolute;
  bottom: -30%;
  left: 42%;
}
.unleashing_total_sec .owl-carousel .owl-nav button.owl-next {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #2C2C2C;
  position: absolute;
  bottom: -30%;
  left: 47%;
}
.unleashing_total_sec .owl-theme .owl-nav [class*=owl-] {
  background: #FFFFFF !important;
  padding: 0 !important;
  color: #2C2C2C !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unleashing_total_sec .service_carousel .serivices_card .service_heading {
  font-family: "Ninetail";
}
.unleashing_total_sec .service_carousel .serivices_card .features_sec {
  display: flex;
  align-items: center;
}
.unleashing_total_sec .service_carousel .serivices_card .features_sec h6 {
  margin-bottom: 0;
  background-color: #EEEEEE;
  color: #000000;
  padding: 5px 15px;
  color: #000000;
  font-size: 14px;
  border-radius: 25px;
  font-weight: 600;
}
.unleashing_total_sec .service_carousel .serivices_card .features_sec span {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
}
.unleashing_total_sec .service_carousel .serivices_card .parra_content p {
  margin-bottom: 0;
  margin-top: 20px;
  text-align: justify;
}

@media only screen and (max-width: 991.98px) {
  .unleashing_total_sec .img_card img {
    width: 100%;
  }
  .unleashing_total_sec .img_card .readmore_btn {
    left: 35%;
  }
  .unleashrow {
    display: flex;
    align-items: center;
  }
  .unleashing_total_sec .service_carousel .serivices_card .features_sec h6 {
    padding: 5px 10px;
    font-size: 10px;
  }
  .unleashing_total_sec .service_carousel .serivices_card .service_heading {
    font-size: 26px;
  }
}
@media only screen and (max-width: 600px) {
  .unleashing_total_sec .service_carousel .serivices_card {
    margin-top: 20px;
  }
  .unleashing_total_sec .service_carousel .serivices_card .service_heading {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .owl-carousel .owl-nav.disabled {
    display: block;
  }
  .unleashing_total_sec .owl-carousel .owl-nav button.owl-next {
    bottom: -18%;
    left: 50%;
    font-size: 27px;
  }
  .unleashing_total_sec .owl-carousel .owl-nav button.owl-prev {
    font-size: 27px;
    bottom: -18%;
    left: 36%;
  }
}
.service_offered_total_sec {
  padding: 50px 0px;
  background-color: #FFF8F3;
}
.service_offered_total_sec .heading_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
}
.service_offered_total_sec .heading_sec .heading {
  color: #C47D3D;
  text-align: center;
  font-family: "Ninetail";
  margin-bottom: 0;
}
.service_offered_total_sec .heading_sec span {
  display: block;
  font-size: 16px;
  margin-right: 10px;
  margin-left: 10px;
}
.service_offered_total_sec .heading_sec .seeall_btn {
  position: absolute;
  right: 0;
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 5px 35px;
  font-size: 16px;
  border: 1px solid #C47D3D;
}
.service_offered_total_sec .heading_sec .seeall_btn:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 1px solid #C47D3D;
  transition: 0.3s ease-in-out;
}
.service_offered_total_sec .serve_carousel {
  position: relative;
}
.service_offered_total_sec .serve_carousel .car_img {
  position: absolute;
  z-index: 2 !important;
  width: 200px;
  right: -3%;
  bottom: 3%;
}
.service_offered_total_sec .serve_carousel .servicesoffered_card {
  width: 80%;
  margin: auto;
  border-radius: 10px;
  padding: 30px;
  box-shadow: -3px 3px 17px 0px rgba(196, 125, 61, 0.5882352941);
  background-color: #FFFFFF;
}
.service_offered_total_sec .serve_carousel .servicesoffered_card h6 {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  margin-top: 20px;
  border-bottom: 1px solid #C47D3D;
  padding-bottom: 10px;
}
.service_offered_total_sec .serve_carousel .servicesoffered_card p {
  text-align: justify;
  font-size: 14px;
}
.service_offered_total_sec .serve_carousel .servicesoffered_card .begun_btn {
  background-color: #2C2C2C;
  font-family: "Ninetail";
  color: #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  border-radius: 25px;
  padding: 5px 30px;
  border: 1px solid #2C2C2C;
}
.service_offered_total_sec .serve_carousel .servicesoffered_card .begun_btn:hover {
  border: 1px solid #2C2C2C;
  background-color: transparent;
  color: #2C2C2C;
  transition: 0.5s ease-in-out;
}
.service_offered_total_sec .serve_carousel .owl-carousel {
  z-index: 4 !important;
}
.service_offered_total_sec .serve_carousel .owl-carousel .owl-stage-outer {
  padding: 50px 0px !important;
}
.service_offered_total_sec .serve_carousel .owl-carousel.owl-drag .owl-item.center .item {
  transform: scale(1.2);
  padding: 0px !important;
  overflow: visible !important;
  border-radius: 15px;
  margin: 10px !important;
}
.service_offered_total_sec .serve_carousel .owl-carousel .owl-nav button.owl-prev {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #C47D3D;
  position: absolute;
  bottom: 50%;
  left: -3%;
}
.service_offered_total_sec .serve_carousel .owl-carousel .owl-nav button.owl-next {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #C47D3D;
  position: absolute;
  bottom: 50%;
  right: -3%;
}
.service_offered_total_sec .serve_carousel .owl-theme .owl-nav [class*=owl-] {
  background: #FFFFFF !important;
  padding: 0 !important;
  color: #C47D3D !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service_offered_total_sec .serve_carousel .owl-carousel .owl-nav.disabled,
.service_offered_total_sec .serve_carousel .owl-carousel .owl-dots.disabled {
  display: block !important;
}
.service_offered_total_sec .serve_carousel .owl-theme .owl-dots .owl-dot.active span,
.service_offered_total_sec .serve_carousel .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #C47D3D;
}
.service_offered_total_sec .serve_carousel .owl-theme .owl-dots .owl-dot span {
  background: #F2E2D4;
}

@media only screen and (max-width: 600px) {
  .service_offered_total_sec .heading_sec .seeall_btn {
    right: 32%;
    bottom: -46px;
  }
  .service_offered_total_sec .serve_carousel .owl-carousel .owl-nav button.owl-prev {
    left: -3.5%;
  }
  .service_offered_total_sec .serve_carousel .owl-carousel .owl-nav button.owl-next {
    right: -4%;
  }
  .service_offered_total_sec .serve_carousel .servicesoffered_card {
    padding: 10px;
  }
}
.whatdid_total_sec {
  padding: 50px 0px;
}
.whatdid_total_sec .content_container {
  padding: 50px 0px;
}
.whatdid_total_sec .content_container .img_sec {
  position: relative;
  text-align: center;
}
.whatdid_total_sec .content_container .img_sec img {
  width: 100%;
}
.whatdid_total_sec .content_container .img_sec .shedule_btn {
  margin-top: 50px;
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 5px 35px;
  font-size: 16px;
  border: 1px solid #C47D3D;
}
.whatdid_total_sec .content_container .img_sec .shedule_btn:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 1px solid #C47D3D;
  transition: 0.3s ease-in-out;
}
.whatdid_total_sec .content_container .total_content_sec {
  position: relative;
}
.whatdid_total_sec .content_container .total_content_sec .whatbefore_illu {
  position: absolute;
  width: 160px;
  right: 0%;
  top: 23%;
}
.whatdid_total_sec .content_container .total_content_sec .exp_head {
  font-weight: 600;
  margin-left: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.whatdid_total_sec .content_container .total_content_sec .exp_head span {
  color: #C47D3D;
}
.whatdid_total_sec .content_container .total_content_sec p {
  text-align: justify;
  margin-left: 10px;
  width: 80%;
}
.whatdid_total_sec .content_container .total_content_sec .counted_row {
  position: relative;
}
.whatdid_total_sec .content_container .total_content_sec .counted_row .divide_img {
  position: absolute;
  width: 35%;
  left: 6.5%;
  top: 0%;
}
.whatdid_total_sec .content_container .total_content_sec .counter_sec {
  text-align: center;
}
.whatdid_total_sec .content_container .total_content_sec .counter_sec h2 {
  color: #000000;
  font-weight: 800;
  margin-left: 10px;
  font-family: "Thiccboi_Bold";
}
.whatdid_total_sec .content_container .total_content_sec .counter_sec .align_para {
  text-align: center;
  width: auto !important;
  color: #C47D3D;
  font-weight: 600;
}

.head_star_sec {
  display: flex;
  align-items: center;
}
.head_star_sec h1 {
  font-family: "Ninetail";
  margin-bottom: 0;
}
.head_star_sec span {
  font-size: 22px;
  margin-bottom: 0;
  margin-right: 10px;
  margin-left: 10px;
}

@media only screen and (max-width: 991.98px) {
  .what_didrow {
    display: flex;
    align-items: center;
  }
  .whatdid_total_sec .content_container .img_sec .shedule_btn {
    right: 13%;
    bottom: -4.5%;
  }
  .whatdid_total_sec .content_container .total_content_sec p {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .whatdid_total_sec .content_container .img_sec .shedule_btn {
    right: 16%;
    bottom: -4.5%;
  }
  .head_star_secthree {
    margin-top: 30px;
  }
  .head_star_secthree span {
    margin-left: 0;
  }
  .whatdid_total_sec .content_container .total_content_sec .exp_head {
    margin-top: 15px;
    margin-left: 0px;
  }
  .whatdid_total_sec .content_container .total_content_sec p {
    margin-left: 0;
  }
  .counter_row {
    display: flex;
  }
  .counter_row .counter_col {
    width: 48%;
  }
  .whatdid_total_sec .content_container .total_content_sec .counted_row .divide_img {
    position: absolute;
    width: 84%;
    left: 6%;
    top: -8%;
    display: none;
  }
}
.total_testimonialsec {
  padding: 50px 0px;
  background-color: #FFF8F3;
}
.total_testimonialsec .head_star_sectwo {
  justify-content: center;
}
.total_testimonialsec .head_star_sectwo h1 {
  color: #C47D3D;
}
.total_testimonialsec .header_sec {
  text-align: center;
}
.total_testimonialsec .header_sec p {
  font-size: 18px;
  color: #C47D3D;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #C47D3D;
  position: relative;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .illu {
  position: absolute;
  width: 40px;
  right: -3%;
  top: -7%;
  display: none;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .rating_sec {
  display: flex;
  align-items: center;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .rating_sec .iconsec {
  margin-bottom: 4px;
  margin-right: 5px;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .rating_sec .iconsec span {
  color: #FFD056;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .rating_sec h5 {
  margin-bottom: 0;
  color: #C47D3D;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .feed_back {
  margin-top: 20px;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .feed_back .name {
  color: #000000;
  font-weight: 600;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .total_desig_sec {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .total_desig_sec img {
  width: 45px;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .total_desig_sec .desigsec h5 {
  color: #C47D3D;
  font-family: "Ninetail";
  margin-bottom: 0;
}
.total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .total_desig_sec .desigsec p {
  font-size: 14px;
  font-weight: 600;
}
.total_testimonialsec .total_carouselsec .owl-carousel .owl-stage-outer {
  padding: 50px 0px !important;
}
.total_testimonialsec .total_carouselsec .owl-carousel.owl-drag .owl-item.center .item {
  transform: scale(1.2);
  padding: 0px !important;
  overflow: visible !important;
  border-radius: 15px;
  margin: 10px !important;
}
.total_testimonialsec .total_carouselsec .owl-carousel.owl-drag .owl-item.center .item .illu {
  display: block !important;
}
.total_testimonialsec .total_carouselsec .owl-carousel .owl-nav button.owl-prev {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #C47D3D;
  position: absolute;
  bottom: 50%;
  left: -5%;
}
.total_testimonialsec .total_carouselsec .owl-carousel .owl-nav button.owl-next {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #C47D3D;
  position: absolute;
  bottom: 50%;
  right: -5%;
}
.total_testimonialsec .total_carouselsec .owl-theme .owl-nav [class*=owl-] {
  background: #FFFFFF !important;
  padding: 0 !important;
  color: #C47D3D !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.total_testimonialsec .total_carouselsec .owl-carousel .owl-nav.disabled,
.total_testimonialsec .total_carouselsec .owl-carousel .owl-dots.disabled {
  display: block !important;
}
.total_testimonialsec .total_carouselsec .owl-theme .owl-dots .owl-dot.active span,
.total_testimonialsec .total_carouselsec .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #C47D3D;
}
.total_testimonialsec .total_carouselsec .owl-theme .owl-dots .owl-dot span {
  background: #F2E2D4;
}

@media only screen and (max-width: 991.98px) {
  .total_testimonialsec .total_carouselsec .owl-carousel.owl-drag .owl-item.center .item {
    transform: scale(1);
    padding: 0px !important;
    overflow: visible !important;
    border-radius: 15px;
    margin: 10px !important;
  }
  .total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card .illu {
    position: absolute;
    width: 40px;
    right: -1%;
    top: -7%;
    display: none;
  }
  .owl-theme .owl-nav {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .total_testimonialsec .total_carouselsec .testimonial_carousel .testimonial_card {
    padding: 15px;
  }
}
.total_formsec {
  padding: 50px 0;
}
.total_formsec .hireme_btn {
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 5px 35px;
  font-size: 22px;
  border: 4px solid #FFFFFF;
  outline: 2px solid rgba(196, 125, 61, 0.3098039216);
  margin-top: 20px;
}
.total_formsec .hireme_btn:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 4px solid #FFFFFF;
  outline: 2px solid rgba(196, 125, 61, 0.3098039216);
  transition: 0.3s ease-in-out;
}
.total_formsec .header_sec {
  text-align: center;
}
.total_formsec .header_sec h1 {
  font-family: "Ninetail";
}
.total_formsec .header_sec .head_star_sectwo {
  justify-content: center;
}
.total_formsec .header_sec .head_star_sectwo h4 {
  color: #C47D3D;
  margin-bottom: 0;
}
.total_formsec .mail_form {
  width: 50%;
  margin: auto;
  margin-top: 50px;
}
.total_formsec .mail_form .form-group {
  position: relative;
  position: relative;
}
.total_formsec .mail_form .form-group input {
  height: 60px;
  border: 1px solid #C47D3D;
  border-radius: 25px;
  font-size: 18px;
  outline: none;
  box-shadow: none;
}
.total_formsec .mail_form .form-group input::-moz-placeholder {
  color: #C4C4C4;
}
.total_formsec .mail_form .form-group input::placeholder {
  color: #C4C4C4;
}
.total_formsec .mail_form .form-group .book_btn {
  position: absolute;
  right: 1%;
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 5px 35px;
  font-size: 16px;
  border: 1px solid #C47D3D;
  padding: 10px 35px;
  top: 13%;
}
.total_formsec .mail_form .form-group .book_btn:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 1px solid #C47D3D;
  transition: 0.3s ease-in-out;
}
.total_formsec .mail_form .achive_sec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.total_formsec .mail_form .achive_sec .first_sec {
  text-align: left;
  background-color: #C47D3D;
  padding: 5px 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 10px;
}
.total_formsec .mail_form .achive_sec .first_sec h6 {
  margin-bottom: 0;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
}
.total_formsec .mail_form .achive_sec .second_sec h6 {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
}
.total_formsec .mail_form .achive_sec .second_sec:hover {
  background-color: #C47D3D;
  padding: 5px 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: 0.5s ease-in-out;
}
.total_formsec .mail_form .achive_sec .second_sec:hover h6 {
  color: #FFFFFF;
}
.total_formsec .mail_form .achive_sec span {
  display: block;
  margin-right: 10px;
}

@media only screen and (max-width: 991.98px) {
  .total_formsec .mail_form {
    width: 80%;
  }
  .total_formsec .mail_form {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .total_formsec .mail_form .achive_sec .first_sec {
    text-align: left;
    background-color: #C47D3D;
    padding: 5px 2px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-right: 4px;
  }
  .total_formsec .mail_form .achive_sec .first_sec h6 {
    font-size: 8px;
  }
  .total_formsec .mail_form .achive_sec .second_sec h6 {
    font-size: 8px;
  }
  .total_formsec .mail_form .achive_sec span {
    font-size: 8px;
  }
  .total_formsec .mail_form .achive_sec .second_sec:hover {
    background-color: #C47D3D;
    padding: 5px 2px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: 0.5s ease-in-out;
  }
}
.total_blogsec {
  padding: 50px 0px;
  background-color: #FFF8F3;
  position: relative;
}
.total_blogsec .see_allbtn {
  position: absolute;
  right: 45%;
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 5px 35px;
  font-size: 20px;
  border: 1px solid #C47D3D;
  bottom: -2.5%;
}
.total_blogsec .see_allbtn:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 1px solid #C47D3D;
  transition: 0.3s ease-in-out;
}
.total_blogsec .head_star_sectwo {
  color: #C47D3D;
  justify-content: center;
}
.total_blogsec .head_star_sectwo span {
  color: #2C2C2C;
}
.total_blogsec .total_carouselsec {
  margin-top: 30px;
}
.total_blogsec .total_carouselsec .owl-carousel .owl-nav.disabled {
  display: block !important;
}
.total_blogsec .total_carouselsec .owl-carousel .owl-nav button.owl-prev {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #C47D3D;
  position: absolute;
  bottom: 50%;
  left: -5%;
  display: none;
}
.total_blogsec .total_carouselsec .owl-carousel .owl-nav button.owl-next {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #C47D3D;
  position: absolute;
  bottom: 50%;
  right: -5%;
}
.total_blogsec .total_carouselsec .owl-theme .owl-nav [class*=owl-] {
  background: #FFFFFF !important;
  padding: 0 !important;
  color: #C47D3D !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.total_blogsec .total_carouselsec .blogs_card {
  padding: 20px;
  border-radius: 5px;
}
.total_blogsec .total_carouselsec .blogs_card img {
  width: 100%;
}
.total_blogsec .total_carouselsec .blogs_card .content_para .readmore {
  color: #C47D3D;
}
.total_blogsec .total_carouselsec .blogs_card .heading {
  color: #C47D3D;
  font-family: "Ninetail";
}
.total_blogsec .total_carouselsec .blogs_card .feature_sec {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.total_blogsec .total_carouselsec .blogs_card .feature_sec h6 {
  margin-bottom: 0;
  font-size: 10px;
  padding: 5px 15px;
  color: #2C2C2C;
  background-color: #EEE8E3;
  border-radius: 25px;
}
.total_blogsec .total_carouselsec .blogs_card .feature_sec span {
  display: block;
  margin-right: 10px;
  margin-left: 10px;
}

.marquee_sec2 {
  padding: 50px 0px;
}

@media only screen and (max-width: 991.98px) {
  .marquee_sec {
    padding: 0px 0px;
  }
  .total_blogsec .see_allbtn {
    right: 40%;
  }
}
@media only screen and (max-width: 600px) {
  .total_blogsec .see_allbtn {
    right: 30%;
  }
}
.blog_banner_sec {
  padding-top: 180px;
  background-color: #FFF8F3;
}
.blog_banner_sec .head {
  font-family: "Ninetail";
  color: #C47D3D;
  font-size: 48px;
}
.blog_banner_sec .head span {
  font-family: "Ninetail";
  color: #2C2C2C;
}
.blog_banner_sec .parra_sec h6 {
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
}
.blog_banner_sec .parra_sec p {
  text-align: right;
  font-weight: 500;
  color: #C47D3D;
}
.blog_banner_sec .author-card {
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.blog_banner_sec .author-card img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.blog_banner_sec .author-card .author-titles {
  margin-left: 15px;
}
.blog_banner_sec .author-card .author-titles h3 {
  font-family: "Ninetail";
  color: #C47D3D;
  font-size: 22px;
  margin-bottom: 0;
}
.blog_banner_sec .author-card .author-titles p {
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  margin-bottom: 0;
}
.blog_banner_sec .blog-detail-img {
  width: 100%;
  margin-bottom: 30px;
}

.brown-bg {
  background-color: #FFF8F3;
}

.blockquote-sec {
  border-left: 5px solid #C47D3D;
  padding-left: 25px;
}
.blockquote-sec h3 {
  font-family: "Ninetail";
  color: #C47D3D;
  font-size: 30px;
}
.blockquote-sec p {
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #000000;
}

.content-section-with-img {
  margin: 30px 0;
}
.content-section-with-img h3 {
  font-family: "Ninetail";
  color: #C47D3D;
  font-size: 36px;
}
.content-section-with-img p {
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  color: #000000;
}
.content-section-with-img img {
  width: 100%;
}

.content-section {
  margin: 30px 0;
}
.content-section h3 {
  font-family: "Ninetail";
  color: #C47D3D;
  font-size: 36px;
}
.content-section p {
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  color: #000000;
}
.content-section img {
  width: 100%;
}
.content-section .list .heading {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
}
.content-section .list ul:not(.heading) li {
  font-size: 18px;
  text-align: left;
  font-size: 16px;
  line-height: 2;
  color: #000000;
  margin-bottom: 20px;
}

.key-concepts .see_allbtn {
  text-align: center;
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 5px 35px;
  font-size: 20px;
  border: 1px solid #C47D3D;
}
.key-concepts .see_allbtn:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 1px solid #C47D3D;
  transition: 0.3s ease-in-out;
}
.key-concepts .content-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.key-concepts .content-sec.split-2 p {
  width: 48%;
}
.key-concepts .content-sec.split-3 p {
  width: 32%;
}
.key-concepts .content-sec p {
  margin-top: 30px;
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  color: #000000;
}
.key-concepts .content-sec p b {
  font-weight: 800;
}

@media only screen and (max-width: 600px) {
  .blog_banner_sec {
    padding-top: 100px;
  }
  .blog_banner_sec h1 {
    font-size: 36px;
  }
  .blog_banner_sec p,
  .blog_banner_sec h6 {
    font-size: 14px;
  }
  .blog_banner_sec .parra_sec h6 {
    font-size: 14px;
  }
  .content-section h3 {
    font-size: 24px;
  }
  .content-section p {
    font-size: 14px;
  }
  .content-section .list .heading {
    font-size: 16px;
  }
  .content-section .list ul:not(.heading) li {
    font-size: 14px;
  }
  .key-concepts .content-sec p {
    font-size: 14px;
  }
  .blockquote-sec h3 {
    font-size: 18px;
  }
  .blockquote-sec p {
    font-size: 14px;
  }
  .content-section-with-img img {
    width: 90% !important;
    margin: auto;
  }
  img.img-responsive {
    width: 90% !important;
    margin: auto;
  }
}
.total_footer_sec {
  background-color: #FFF8F3;
  padding: 50px 0px;
}
.total_footer_sec .bottom_content {
  text-align: center;
  color: #C47D3D;
  margin-top: 30px;
}
.total_footer_sec .bottom_content span {
  color: #000000;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
}
.total_footer_sec .total_contentsec {
  display: flex;
  justify-content: space-between;
}
.total_footer_sec .total_contentsec .left_sec {
  width: 30%;
}
.total_footer_sec .total_contentsec .left_sec .namesec {
  width: -moz-max-content;
  width: max-content;
}
.total_footer_sec .total_contentsec .left_sec .namesec img {
  width: 90%;
}
.total_footer_sec .total_contentsec .left_sec .namesec h1 {
  font-family: "Ninetail";
  font-size: 58px;
  font-weight: 600;
  padding: 0;
  margin-bottom: 0;
}
.total_footer_sec .total_contentsec .left_sec .namesec p {
  color: #C47D3D;
  text-align: right;
  padding: 0;
  font-weight: 600;
}
.total_footer_sec .total_contentsec .left_sec .content {
  text-align: justify;
  color: #2C2C2C;
  margin-top: 30px;
}
.total_footer_sec .total_contentsec .left_sec .content span {
  font-weight: 600;
}
.total_footer_sec .total_contentsec .left_sec .social_sec {
  display: flex;
}
.total_footer_sec .total_contentsec .left_sec .social_sec span {
  width: 30px;
  height: 30px;
  background-color: #C47D3D;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.total_footer_sec .total_contentsec .left_sec .social_sec span a {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 14px;
}
.total_footer_sec .total_contentsec .right_sec {
  text-align: right;
}
.total_footer_sec .total_contentsec .right_sec .hireme_btn {
  background-color: #C47D3D;
  color: #FFFFFF;
  font-family: "Ninetail";
  border-radius: 25px;
  padding: 5px 35px;
  font-size: 22px;
  border: 4px solid #FFFFFF;
  outline: 2px solid rgba(196, 125, 61, 0.3098039216);
}
.total_footer_sec .total_contentsec .right_sec .hireme_btn:hover {
  background-color: #FFFFFF;
  color: #C47D3D;
  border: 4px solid #FFFFFF;
  outline: 2px solid rgba(196, 125, 61, 0.3098039216);
  transition: 0.3s ease-in-out;
}
.total_footer_sec .total_contentsec .right_sec .address_sec {
  text-align: right;
  margin-top: 30px;
}
.total_footer_sec .total_contentsec .right_sec .address_sec a {
  display: flex;
  justify-content: end;
  color: #2C2C2C;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 14px;
}
.total_footer_sec .total_contentsec .right_sec .address_sec a span {
  display: block;
}
.total_footer_sec .total_contentsec .right_sec .address_sec a .icon {
  margin-left: 20px;
  color: #C47D3D;
}

@media only screen and (max-width: 991.98px) {
  .total_footer_sec .total_contentsec .left_sec {
    width: 45%;
  }
  .total_footer_sec .total_contentsec .right_sec {
    width: 45%;
  }
}
@media only screen and (max-width: 600px) {
  .total_footer_sec .total_contentsec {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .total_footer_sec .total_contentsec .left_sec {
    width: 100%;
  }
  .total_footer_sec .total_contentsec .right_sec {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }
  .total_footer_sec .total_contentsec .right_sec .address_sec {
    text-align: left;
  }
  .total_footer_sec .total_contentsec .right_sec .address_sec a {
    display: flex;
    justify-content: start;
    color: #2C2C2C;
    text-decoration: none;
    margin-bottom: 15px;
    font-size: 14px;
    flex-direction: row-reverse;
  }
  .total_footer_sec .total_contentsec .right_sec .address_sec a .icon {
    margin-left: 0;
    margin-right: 20px;
  }
  .total_footer_sec .total_contentsec .left_sec .namesec img {
    width: 80%;
  }
}
.rfm-marquee-container {
  overflow-y: hidden !important;
}/*# sourceMappingURL=style.css.map */