.unleashing_total_sec {
    padding: 100px 0px;

    .img_card {
        position: relative;

        img {
            width: 90%;
        }

        .readmore_btn {
            position: absolute;
            left: 60%;
            bottom: 3%;
            background-color: $text_white;
            color: $text_black;
            font-weight: 600;
            font-size: 13px;
            border: 1px solid $text_white;
            border-radius: 25px;
            padding: 5px 20px;

            &:hover {
                background-color: transparent;
                color: $text_white;
                border: 1px solid $text_white;
                transition: 0.5s ease-in-out;
            }
        }
    }

    .owl-carousel .owl-nav button.owl-prev {
        background-color: none;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #2C2C2C;
        position: absolute;
        bottom: -30%;
        left: 42%;
    }

    .owl-carousel .owl-nav button.owl-next {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #2C2C2C;
        position: absolute;
        bottom: -30%;
        left: 47%;
    }

    .owl-theme .owl-nav [class*="owl-"] {
        background: $text_white !important;
        padding: 0 !important;
        color: #2C2C2C !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .service_carousel {
        .serivices_card {
            .service_heading {
                font-family: $secondary_font;
            }

            .features_sec {
                display: flex;
                align-items: center;

                h6 {
                    margin-bottom: 0;
                    background-color: $light_background;
                    color: $text_black;
                    padding: 5px 15px;
                    color: $text_black;
                    font-size: 14px;
                    border-radius: 25px;
                    font-weight: 600;
                }

                span {
                    display: block;
                    // margin-bottom: 0;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }

            .parra_content {
                p {
                    margin-bottom: 0;
                    margin-top: 20px;
                    text-align: justify;
                }
            }
        }
    }
}

@media only screen and (max-width: 991.98px) {
    .unleashing_total_sec .img_card img {
        width: 100%;
    }

    .unleashing_total_sec .img_card .readmore_btn {
        left: 35%;
    }

    .unleashrow {
        display: flex;
        align-items: center;
    }

    .unleashing_total_sec .service_carousel .serivices_card .features_sec h6 {
        padding: 5px 10px;
        font-size: 10px;
    }

    .unleashing_total_sec .service_carousel .serivices_card .service_heading {
        font-size: 26px;
    }
}

@media only screen and (max-width: 767.98px) {}

@media only screen and (max-width: 600px) {
    .unleashing_total_sec .service_carousel .serivices_card {
        margin-top: 20px;
    }

    .unleashing_total_sec .service_carousel .serivices_card .service_heading {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .owl-carousel .owl-nav.disabled {
        display: block;
    }

    .unleashing_total_sec .owl-carousel .owl-nav button.owl-next {
        bottom: -18%;
        left: 50%;
        font-size: 27px;

    }

    .unleashing_total_sec .owl-carousel .owl-nav button.owl-prev {
        font-size: 27px;
        bottom: -18%;
        left: 36%;
    }
}