.blog_banner_sec {
    padding-top: 180px;
    background-color: $primary_background;



    .head {
        font-family: "Ninetail";
        color: $primary_color;
        font-size: 48px;

        span {
            font-family: "Ninetail";
            color: $dark_background;
        }
    }


    .parra_sec {

        h6 {
            text-align: justify;
            font-size: 18px;
            line-height: 1.5;
        }

        p {
            text-align: right;
            font-weight: 500;
            color: $primary_color;
        }
    }

    .author-card {
        display: flex;
        align-items: center;
        margin: 30px 0;

        img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
        }

        .author-titles {
            margin-left: 15px;

            h3 {
                font-family: "Ninetail";
                color: $primary_color;
                font-size: 22px;
                margin-bottom: 0;
            }

            p {
                font-weight: 400;
                color: $text_black;
                font-size: 14px;
                margin-bottom: 0;
            }
        }
    }

    .blog-detail-img {
        width: 100%;
        margin-bottom: 30px;
    }

}

.brown-bg {
    background-color: $primary_background;
}

.blockquote-sec {
    border-left: 5px solid $primary_color;
    padding-left: 25px;

    h3 {
        font-family: "Ninetail";
        color: $primary_color;
        font-size: 30px;
    }

    p {
        text-align: left;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        color: $text_black;
    }
}

.content-section-with-img {
    margin: 30px 0;

    h3 {
        font-family: "Ninetail";
        color: $primary_color;
        font-size: 36px;
    }

    p {
        text-align: left;
        font-size: 18px;
        line-height: 1.5;
        font-weight: 500;
        color: $text_black;
    }

    img {
        width: 100%;
    }
}

.content-section {
    margin: 30px 0;

    h3 {
        font-family: "Ninetail";
        color: $primary_color;
        font-size: 36px;
    }

    p {
        text-align: left;
        font-size: 18px;
        line-height: 1.5;
        font-weight: 500;
        color: $text_black;
    }

    img {
        width: 100%;
    }

    .list {
        .heading {
            font-weight: bold;
            font-size: 18px;
            text-align: left;
            font-size: 18px;
            line-height: 1.5;
            color: $text_black;
        }

        ul:not(.heading) li {
            font-size: 18px;
            text-align: left;
            font-size: 16px;
            line-height: 2;
            color: $text_black;
            margin-bottom: 20px;
        }
    }
}

.key-concepts {
    .see_allbtn {
        text-align: center;
        background-color: #C47D3D;
        color: #FFFFFF;
        font-family: "Ninetail";
        border-radius: 25px;
        padding: 5px 35px;
        font-size: 20px;
        border: 1px solid #C47D3D;


        &:hover {
            background-color: $text_white;
            color: $primary_color;
            border: 1px solid $primary_color;
            transition: 0.3s ease-in-out;
        }
    }

    .content-sec {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.split-2 {
            p {
                width: 48%;
            }
        }

        &.split-3 {
            p {
                width: 32%;
            }
        }

        p {
            margin-top: 30px;
            text-align: left;
            font-size: 18px;
            line-height: 1.5;
            font-weight: 500;
            color: $text_black;

            b {
                font-weight: 800;
            }
        }
    }
}

// @media only screen and (max-width: 991.98px) {
//     .total_banner_sec {
//         height: 60vh;
//         padding-top: 160px;
//     }

//     .total_banner_sec .head {
//         font-size: 36px;
//     }

//     .total_banner_sec .btnsec .hire_btn {
//         font-size: 22px;
//     }



//     .total_banner_sec .img_sec img {
//         width: 100%;
//         margin-top: 0;
//     }

// }

// @media only screen and (max-width: 767.98px) {}

@media only screen and (max-width: 600px) {
    .blog_banner_sec {
        padding-top: 100px;

        h1 {
            font-size: 36px;
        }

        p,
        h6 {
            font-size: 14px
        }

        .parra_sec {
            h6 {
                font-size: 14px;
            }
        }
    }

    .content-section {
        h3 {
            font-size: 24px;
        }

        p {
            font-size: 14px
        }

        .list {
            .heading{
                font-size: 16px;
            }
            ul:not(.heading){

                li {
                    font-size: 14px
                }
            }
        }

    }

    .key-concepts {
        .content-sec {
            p {
                font-size: 14px;
            }
        }
    }

    .blockquote-sec {
        h3 {
            font-size: 18px;
        }

        p {
            font-size: 14px;
        }
    }

    .content-section-with-img{
        img{
            width: 90% !important;
            margin:auto;
        }
    }

    img.img-responsive{
        width: 90% !important;
        margin:auto;
    }



}